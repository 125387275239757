<template>
  <div class="recurring-schedule">
    <v-container fluid class="pt-0">
      <v-row
        dense
        class="schedule-tab-item"
        :class="{ 'd-block': showCalendar }"
      >
        <v-col :md="showCalendar ? 12 : 8">
          <div class="schedule-tab-item-header custom-grey-border p-3">
            <RecurringScheduleHeader
              :showCalendar.sync="showCalendar"
            ></RecurringScheduleHeader>
          </div>
          <div class="schedule-tab-item-body custom-grey-border p-3">
            <RecurringScheduleBody
              :showCalendar.sync="showCalendar"
            ></RecurringScheduleBody>
          </div>
        </v-col>
        <v-col :md="showCalendar ? 12 : 4">
          <JobScheduleTeam></JobScheduleTeam>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import RecurringScheduleHeader from "@/view/pages/job/partials/Recurring-Schedule-Header.vue";
import RecurringScheduleBody from "@/view/pages/job/partials/Recurring-Schedule-Body.vue";
import JobScheduleTeam from "@/view/pages/job/partials/Job-Schedule-Team.vue";

export default {
  name: "recurring-schedule",
  props: {
    showCalendar: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  components: {
    RecurringScheduleHeader,
    RecurringScheduleBody,
    JobScheduleTeam
  }
};
</script>
